import {Router, UrlCreationOptions} from '@angular/router';

export const copyTextToClipboard = (text: string): void => {
	navigator.clipboard.writeText(text);
};
export const openInNewWindow = (router: Router, commands: Array<any>, extras?: UrlCreationOptions): void => {
	const url: string = router.serializeUrl(
		router.createUrlTree(commands, extras)
	);
	window.open(url, '_blank');
};
