<div class="editor-wrapper">
	<div *ngIf="placeholder && !isFocused && _value === ''" class="placeholder">
		{{placeholder}}
	</div>
	<editor *ngIf="!disabled"
	        [init]="initTinymce()"
	        (focusin)="isFocused = true"
	        (focusout)="isFocused = false"
	        [(ngModel)]="value"></editor>
	<div *ngIf="disabled"
	     class="disabled"
	     [innerHTML]="value | safeHtmlSanitizer"></div>
</div>

