import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextEditorComponent} from './text-editor.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {FormsModule} from '@angular/forms';
import {SafeHtmlSanitizerModule} from '../../pipes/safe-html-sanitizer/safe-html-sanitizer.module';

@NgModule({
	declarations: [
		TextEditorComponent
	],
	exports: [
		TextEditorComponent
	],
	imports: [
		CommonModule,
		EditorModule,
		FormsModule,
		SafeHtmlSanitizerModule
	]
})
export class TextEditorModule {
}
