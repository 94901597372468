import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const enum ToolBarOptions {
	bold = 'bold',
	italic = 'italic',
	foreColor = 'foreColor',
	backColor = 'backColor'
}

@Component({
	selector: 'aga-text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: ['./text-editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextEditorComponent,
			multi: true
		}
	]
})
export class TextEditorComponent implements ControlValueAccessor {
	@Input() disabled: boolean;
	@Input() isEditMode: boolean = true;
	@Input() _value: string;
	@Input() placeholder: string;
	@Input() toolBarOptions: Array<string>;
	isFocused: boolean;

	onChange: any = () => {
	};
	onTouch: any = () => {
	};

	initTinymce(): object {
		return {
			menubar: false,
			statusbar: false,
			plugins: 'link paste textcolor',
			toolbar: 'bold italic forecolor backcolor',
			height: '100%',
			paste_as_text: true,
			force_br_newlines : true,
			force_p_newlines : false,
			forced_root_block : ''
		};
	}

	get value() {
		return this._value;
	}

	set value(value: string) {
		this._value = value;
		this.onChange(value);
		this.onTouch(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(value: string): void {
		this.value = value;
	}
}
